import store from '@/store'
const authguard = (to, from, next) => {
  return next()
    // if(localStorage.getItem('smsadvantage_client' + store.getters.authuser.id) == null && store.getters.authuser == null && store.getters.authuser.name ){
    //     return next()
    // }else{
    //     return next({ name: 'welcome' })
    // }
};

let routes = [

    {
        path: "/auth/",
        props:true,
        component: () => import("@/views/auth/main.vue"),


        children: [
          {
            path: "login",
            name: "login",
            beforeEnter: authguard,
            props:true,
            component: () => import("@/views/auth/login.vue"),
            meta: {
             title: 'Login to Your Dashboard'
            }
          },

          {
            path: "create-account",
            name: "create_account",
            beforeEnter: authguard,
            props:true,
            component: () => import("@/views/auth/register.vue"),
            meta: {
             title: 'Create An Account'
            }
          },

          {
            path: "forgot-password/email",
            name: "forgot_password",
            beforeEnter: authguard,
            props:true,
            component: () => import("@/views/auth/forgot_password.vue"),
            meta: {
             title: 'Forgot Password'
            }
          },

         {
            path: "forgot-password/phone",
            name: "forgot_password_phone",
            beforeEnter: authguard,
            props:true,
            component: () => import("@/views/auth/forgot_password_phone.vue"),
            meta: {
             title: 'Forgot Password'
            }
          },
          {
            path: "forgot-password/verify/:contact",
            name: "verify_reset_code",
            beforeEnter: authguard,
            props:true,
            component: () => import("@/views/auth/verify_reset_code.vue"),
            meta: {
             title: 'Forgot Password'
            }
          },

          {
            path: "terms-and-conditions",
            name: "terms_conditions",
            beforeEnter: authguard,
            props:true,
            component: () => import("@/views/auth/terms.vue"),
            meta: {
             title: 'Read through our Terms and Conditions'
            }
          },

          {
            path: "privacy-policy",
            name: "privacy_policy",
            beforeEnter: authguard,
            props:true,
            component: () => import("@/views/auth/privacy_policy.vue"),
            meta: {
             title: 'Read through our Privacy Policy'
            }
          },

          {
            path: "refund-policy",
            name: "refund_policy",
            beforeEnter: authguard,
            props:true,
            component: () => import("@/views/auth/refund_policy.vue"),
            meta: {
             title: 'Read through our Refund Policy'
            }
          },
          
        ]
    },

]
export default routes;