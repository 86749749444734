<template>
      <router-view></router-view>
      
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default{
    methods:{
        ...mapActions(['getuserlocation','getuseripaddress','get_user_device_details'])
    },



    created(){
        this.getuseripaddress().then(()=>{
            this.getuserlocation();
            this.get_user_device_details();
        })
    }
}
</script>

