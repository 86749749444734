export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
const toast = useToast()

export default {
    state : {
        adverts :'',
        advert : '',

    },
    
    getters : {
        adverts: (state )=> state.adverts,
        advert: (state )=> state.advert,
       
    },


    mutations : { 
        setadverts :(state,adverts ) => (state.adverts = adverts), 
        setadvert :(state,advert ) => (state.advert = advert), 
    },

    actions : {


        async get_user_adverts( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_user_adverts')
            return new Promise((resolve, reject) => {
            axios.post("/user/adverts?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                commit('setadverts', response.data.data)
                console.log(response.data)
                    resolve(response);
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async generate_advert( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'generate_advert')
            return new Promise((resolve, reject) => {
            axios.post("/user/adverts/start", data)
            .then(response => {	
                commit('setloader', false)
                commit('setadvert', response.data.data)
                console.log(response.data)
                    resolve(response);
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async save_advert_draft( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'generate_advert')
            return new Promise((resolve, reject) => {
            axios.post("/user/adverts/save", data)
            .then(response => {	
                commit('setloader', false)
                commit('setadvert', response.data.data)
                console.log(response.data)
                    resolve(response);
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },


    async run_advert( { commit }, data) {
        commit('seterror', '')
        commit('setmsg', '')
        commit('setloader', 'generate_advert')
        return new Promise((resolve, reject) => {
        axios.post("/user/adverts/run", data)
        .then(response => {	
            commit('setloader', false)
            commit('setadvert', response.data.data)
            console.log(response.data)
                resolve(response);
        })
        .catch(error=> {
            commit('setloader', false)
            if (error.response) {
                if(error.response.data){
                    if(error.response.data.msg){
                        commit('seterror', error.response.data.msg)
                        // toast.error(error.response.data.msg)
                    }else if(error.response.data.message){
                        commit('seterrors', error.response.data.message)
                    }
                    
                }
            }
            reject(error)
        });
        });	       
    },
   
}













