import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './axios'
import './assets/app_style.css'
import loadingBtn from './views/comps/loading_button'
import vue3GoogleLogin from 'vue3-google-login'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
library.add(far);
library.add(fab);
library.add(fas);
dom.watch();


let CLIENTID = "381258396702-94itbkqiqnv7omn5of7u47cpqf14251f.apps.googleusercontent.com"

const app = createApp(App)
app.config.globalProperties.$filters = {
    dateToday() {
        var today = new Date();
        return moment(today).format("DD/MM/YYYY")
    },

    timeAgo(date) {
    return moment(date).fromNow()
    },

    dateonly(date) {
        return moment(date).format("DD/MM/YYYY")
    },

    datetime(date){
        return moment(date).format("YYYY-MM-DD HH:mm:ss")
    },

    futuredatetime(date){
        return moment(date).format("dddd MMMM YYYY [at] h:mma")
    },

    textdatetime(date){
        return moment(date).format('llll')
    },
    textdateday(date){
        return moment(date).format('MMMM, Do')
    },
    timeonly(date){
        return moment(date).format("HH:mm:ss")
    },
    convertTimeOnly(date) {
        return moment(date).format('hh:mm A');
    },

    remove_dash(value){
        if (!value) return ''
        return value.replace(/_/g, ' ') 
    },

    currency(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
}


app.use(vue3GoogleLogin, {clientId: CLIENTID}).component("loadingBtn", loadingBtn).component("font-awesome-icon", FontAwesomeIcon).use(store).use(router).mount('#app')

