import store from '@/store'

const adminguard = (to, from, next) => {
    if(localStorage.getItem('smsadvantage_client' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.name ){
      if(store.getters.authuser.email_verified_at == ''){
        return next({ name: 'verify_email' })
      }else{
        return next()
      }
    }else{
        return next({ name: 'login' })
    }
};


let routes = [
    { 
        path: "/",
        props: true,
        component: () => import("@/views/app/main.vue"),
        title:'',

          children: [

            {
              path: "/",
              name: "welcome",
              props:true,
              component: () => import("@/views/app/home.vue"),
              meta: {
              title: 'Manage Ongoing Orders and Create New ones'
              }
            },

            {
              path: "/user",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/main/main.vue"),
              children: [
                {
                  path: "",
                  name: "user",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/main/home.vue"),
                  meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                  }
                },

                
              ]
            },


            {
              path: "/advert-bot",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/bot/main.vue"),
              children: [
                {
                  path: "",
                  name: "advert_bot",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/bot/bot.vue"),
                  meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                  }
                },
              ]
            },
            {
            path: "/profile",
            name: "",
            props:true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/Profile/main.vue"),
            children: [
              {
                path: "",
                name: "profile",
                props:true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/Profile/components/overview.vue"),
                meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                }
              },
              
              {
                path: "settings",
                name: "settings",
                props:true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/Profile/components/settings.vue"),
                meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                }
              },
              {
                path: "billings",
                name: "billing",
                props:true,
                beforeEnter: adminguard,
                component: () => import("@/views/app/Profile/components/billings.vue"),
                meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                }
              },
          
        ]
      },
      {
        path: "/about-us",
        name: "",
        props:true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/about/main.vue"),
        children: [
          {
            path: "",
            name: "about-us",
            props:true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/about/about.vue"),
            meta: {
              title: 'Manage Ongoing Orders and Create New ones'
            }
          },

        ]
      }, 
    ]
  },

  {
      path: "/auth/",
      props:true,
      component: () => import("@/views/auth/main.vue"),
      children: [

        {
          path: "verify-email",
          name: "verify_email",
          beforeEnter: adminguard,
          props:true,
          component: () => import("@/views/auth/verify_email.vue"),
          meta: {
          title: 'Verify Email'
          }
        },
      ]
  },
]
export default routes;