import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import adverts  from './modules/adverts'

export default createStore({
  modules: {
    auth, 
    utils,
    adverts,
  },
    
  plugins: [createPersistedState(
    {
      key : 'smsadvanatage_client.pro',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
