<template>
    <button :class="['button', className]" type="button" v-if="loadingvalue != '' && loadingvalue === loading">
        <svg class="animate-spin" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M15.098 12.634L13 11.423V7a1 1 0 0 0-2 0v5a1 1 0 0 0 .5.866l2.598 1.5a1 1 0 1 0 1-1.732M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2m0 18a8 8 0 1 1 8-8a8.01 8.01 0 0 1-8 8"/></svg>
      {{ loadingTxt }}
    </button>
    
    <button v-else :class="['button', className]" >{{  btnText }}
    </button>
    
      
    </template>
    <script>
    import { mapGetters } from 'vuex'
    export default {
    
        props: {
            
            loadingvalue: {
                type: [String, Boolean, Number],
                default: ''
            },
            loadingTxt: {
                type: String,
                default: 'Loading...'
            },
            className: {
                type: String,
                default: 'inline-flex w-full items-center justify-center gap-3 rounded-md bg-indigo-600 px-5 py-2 text-sm font-medium text-white transition-all hover:bg-indigo-800'
            },
            btnText: {
                type: String,
                default: 'Submit'
            }
        },
    
        computed:{
            ...mapGetters(['loading'])
        },
    
        methods: {
            handleClick() {
                if(!this.loading) {
                this.onClick()
                }
            }
        },
    }
    </script>